import React from 'react'

const Yhteystiedot = () => {
    return (
        <div className="yhteystiedot">
            <a href="mailto:master.korhonen@kolumbus.fi">master.korhonen@kolumbus.fi</a>
            <p>040 591 5178</p>
        </div>
    )
}

export default Yhteystiedot